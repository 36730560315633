import ApiService from "@/core/services/ApiService"
import JwtService from "@/core/services/JwtService"
import { Actions, Mutations } from "@/store/enums/StoreEnums"
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators"
import { AxiosRequestConfig } from "axios"

export interface AddPointRecord {
  id: number
  point: number
}

export interface AddPointRecordInfo {
  add_point_records: Array<AddPointRecord>
  add_point_record: AddPointRecord
}

@Module
export default class AddPointRecordModule
  extends VuexModule
  implements AddPointRecordInfo
{
  add_point_records = [] as Array<AddPointRecord>
  add_point_record_count = 0
  add_point_record = {} as AddPointRecord

  get currentAddPointRecord(): AddPointRecord {
    return this.add_point_record
  }

  get currentAddPointRecords(): Array<AddPointRecord> {
    return this.add_point_records
  }

  get currentAddPointRecordCount() {
    return this.add_point_record_count
  }

  // get getErrors() {
  //   return this.errors;
  // }

  @Mutation
  [Mutations.SET_ADD_POINT_RECORDS](add_point_records) {
    this.add_point_records = add_point_records
  }

  @Mutation
  [Mutations.SET_ADD_POINT_RECORD](add_point_record) {
    this.add_point_record = add_point_record
  }

  @Mutation
  [Mutations.SET_ADD_POINT_RECORD_COUNT](add_point_record_count) {
    this.add_point_record_count = add_point_record_count
  }

  @Action
  [Actions.GET_ADD_POINT_RECORDS](params) {
    ApiService.setHeader()

    let queryString = "?"

    if (params) {
      Object.keys(params).forEach((i) => {
        queryString += "&" + i + "=" + params[i]
      })
    }

    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/add_point_records`, queryString)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_ADD_POINT_RECORDS,
            data["add_point_records"]
          );
          this.context.commit(
            Mutations.SET_ADD_POINT_RECORD_COUNT,
            data["total_result"]
          );
          resolve()
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject()
        })
    })
  }

  @Action
  [Actions.CREATE_ADD_POINT_RECORD](params) {
    ApiService.setHeader()

    return new Promise<void>((resolve, reject) => {
      ApiService.post(`admin/add_point_records`, params)
        .then(({ data }) => {
          if (!data.success) {
            this.context.commit(Mutations.SET_ERROR, data.error_msg)
            reject()
          }

          this.context.commit(
            Mutations.SET_ADD_POINT_RECORD,
            data["add_point_record"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject()
        })
    })
  }
}
