import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";

export interface PcmVersion {
  id: number
  name: string
}

export interface PcmVersionOption {
  id: string
  name: string
}

export interface PcmVersionInfo {
  pcm_versions: Array<PcmVersion>;
  pcm_version: PcmVersion;
}

@Module
export default class PcmVersionModule
  extends VuexModule
  implements PcmVersionInfo
{
  pcm_versions = [] as Array<PcmVersion>;
  pcm_version_count = 0;
  pcm_version = {} as PcmVersion;

  get currentPcmVersion(): PcmVersion {
    return this.pcm_version;
  }

  get currentPcmVersions(): Array<PcmVersion> {
    return this.pcm_versions;
  }

  get currentPcmVersionCount() {
    return this.pcm_version_count;
  }

  // get getErrors() {
  //   return this.errors;
  // }

  @Mutation
  [Mutations.SET_PCM_VERSION](pcm_version) {
    this.pcm_version = pcm_version
  }

  @Mutation
  [Mutations.SET_PCM_VERSIONS](pcm_versions) {
    this.pcm_versions = pcm_versions
  }

  @Mutation
  [Mutations.SET_PCM_VERSION_COUNT](pcm_version_count) {
    this.pcm_version_count = pcm_version_count
  }

  @Action
  [Actions.GET_PCM_VERSIONS](params) {
    ApiService.setHeader()

    let queryString = "?"

    if (params) {
      Object.keys(params).forEach((i) => {
        queryString += "&" + i + "=" + params[i];
      });
    }

    return new Promise<void>((resolve, reject) => {
      ApiService.get("admin/pcm_versions", queryString)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_PCM_VERSIONS,
            data["pcm_versions"]
          );
          this.context.commit(
            Mutations.SET_PCM_VERSION_COUNT,
            data["total_result"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject();
        });
    });
  }

  @Action
  [Actions.GET_PCM_VERSION](id) {
    ApiService.setHeader();

    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/pcm_versions/${id}`)
        .then(({ data }) => {
          console.log(data["pcm_version"])
          this.context.commit(
            Mutations.SET_PCM_VERSION,
            data["pcm_version"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject();
        });
    });
  }

  @Action
  [Actions.CREATE_PCM_VERSION](credentials) {
    ApiService.setHeader()

    const params = {
      pcm_version: {
        ...credentials,
      },
    };

    return new Promise<void>((resolve, reject) => {
      ApiService.post(`admin/pcm_versions`, params)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_PCM_VERSION,
            data["pcm_version"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject();
        });
    });
  }

  @Action
  [Actions.SYNC_PCM_VERSION](id) {
    ApiService.setHeader()

    const params = {}

    return new Promise<void>((resolve, reject) => {
      ApiService.put(`admin/pcm_versions/${id}/sync`, params)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_PCM_VERSION,
            data["pcm_version"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject();
        });
    });
  }
}
