import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";

export interface Ip {
  id: number
  name: string
}

export interface IpInfo {
  ips: Array<Ip>;
  ip: Ip;
}

@Module
export default class IpModule
  extends VuexModule
  implements IpInfo
{
  ips = [] as Array<Ip>;
  ip_count = 0;
  ip = {} as Ip;

  get currentIp(): Ip {
    return this.ip;
  }

  get currentIps(): Array<Ip> {
    return this.ips;
  }

  get currentIpCount() {
    return this.ip_count;
  }

  // get getErrors() {
  //   return this.errors;
  // }

  @Mutation
  [Mutations.SET_IP](ip) {
    this.ip = ip
  }

  @Mutation
  [Mutations.SET_IPS](ips) {
    this.ips = ips
  }

  @Mutation
  [Mutations.SET_IP_COUNT](ip_count) {
    this.ip_count = ip_count
  }

  @Action
  [Actions.GET_IPS](params) {
    ApiService.setHeader()

    let queryString = "?"

    if (params) {
      Object.keys(params).forEach((i) => {
        queryString += "&" + i + "=" + params[i];
      });
    }

    return new Promise<void>((resolve, reject) => {
      ApiService.get("admin/ips", queryString)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_IPS,
            data["ips"]
          );
          this.context.commit(
            Mutations.SET_IP_COUNT,
            data["total_result"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject();
        });
    });
  }

  @Action
  [Actions.GET_IP](id) {
    ApiService.setHeader();

    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/ips/${id}`)
        .then(({ data }) => {
          console.log(data["ip"])
          this.context.commit(
            Mutations.SET_IP,
            data["ip"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject();
        });
    });
  }

  @Action
  [Actions.CREATE_IP](credentials) {
    ApiService.setHeader()

    const params = {
      ip: {
        ...credentials,
      },
    };

    return new Promise<void>((resolve, reject) => {
      ApiService.post(`admin/ips`, params)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_IP,
            data["ip"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_IP](credentials) {
    ApiService.setHeader()

    const params = {
      ip: {
        ...credentials.values,
      },
    };

    return new Promise<void>((resolve, reject) => {
      ApiService.put(`admin/ips/${credentials.id}`, params)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_IP,
            data["ip"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_IP](id) {
    ApiService.setHeader()

    return new Promise<void>((resolve, reject) => {
      ApiService.delete(`admin/ips/${id}`)
        .then(({ data }) => {
          resolve()
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject()
        })
    })
  }
}
