import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";

export interface ShippingOrder {
  id: number
  shipping_order_nickname: string
  shipping_order_mobile: string
  nickname: string
  mobile: string
  area: string
  adress_detail: string
  note: string
  status: string
  status_display: string
  created_at: string
  updated_at: string
}

@Module
export default class ShippingOrderModule extends VuexModule {
  shipping_orders = [] as Array<ShippingOrder>
  shipping_order_count = 0
  shipping_order = {} as ShippingOrder

  get currentShippingOrders(): Array<ShippingOrder> {
    return this.shipping_orders
  }

  get currentShippingOrderCount() {
    return this.shipping_order_count
  }

  get currentShippingOrder(): ShippingOrder {
    return this.shipping_order
  }

  @Mutation
  [Mutations.SET_SHIPPING_ORDERS](shipping_orders) {
    this.shipping_orders = shipping_orders;
  }

  @Mutation
  [Mutations.SET_SHIPPING_ORDER_COUNT](shipping_order_count) {
    this.shipping_order_count = shipping_order_count;
  }

  @Mutation
  [Mutations.SET_SHIPPING_ORDER](shipping_order) {
    this.shipping_order = shipping_order;
  }

  @Action
  [Actions.GET_SHIPPING_ORDERS](params) {
    ApiService.setHeader();

    let queryString = "?";

    if (params) {
      Object.keys(params).forEach((i) => {
        queryString += "&" + i + "=" + params[i];
      });
    }

    return new Promise<void>((resolve, reject) => {
      ApiService.get("admin/shipping_orders", queryString)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_SHIPPING_ORDERS, data["shipping_orders"]);
          this.context.commit(Mutations.SET_SHIPPING_ORDER_COUNT, data["total_result"]);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_SHIPPING_ORDER](id) {
    ApiService.setHeader()

    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/shipping_orders/${id}`)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_SHIPPING_ORDER,
            data["shipping_order"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject()
        })
    })
  }

  @Action
  [Actions.UPDATE_SHIPPING_ORDER](credentials) {
    ApiService.setHeader()

    const params = {
      shipping_order: {
        ...credentials.values,
      },
    };

    return new Promise<void>((resolve, reject) => {
      ApiService.put(`admin/shipping_orders/${credentials.id}`, params)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_USER,
            data["shipping_order"]
          );
          resolve()
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject()
        })
    })
  }
}
