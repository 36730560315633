import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";

export interface Event {
  name: string
  enabled: boolean
  sorting: number
  event_items_attributes: Array<EventItem>
}

export interface EventInfo {
  event: Event
}
export interface EventItem {
  product_id: number,
  product: string,
  brand: string,
  price: number,
  recycle_price: number,
  point: number,
  limit_quantity: number,
  stock: number,
  sorting: number
}


@Module
export default class EventModule extends VuexModule implements EventInfo {
  events = [] as Array<Event>
  event_count = 0
  event = {} as Event

  get currentEvent(): Event {
    return this.event;
  }

  get currentEvents(): Array<Event> {
    return this.events;
  }

  get currentEventCount() {
    return this.event_count;
  }

  // get getErrors() {
  //   return this.errors;
  // }

  @Mutation
  [Mutations.SET_EVENT](event) {
    this.event = event;
  }

  @Mutation
  [Mutations.SET_EVENTS](events) {
    this.events = events;
  }

  @Mutation
  [Mutations.SET_EVENT_COUNT](event_count) {
    this.event_count = event_count;
  }

  @Action
  [Actions.GET_EVENTS](params) {
    ApiService.setHeader();

    let queryString = "?";

    if (params) {
      Object.keys(params).forEach((i) => {
        queryString += "&" + i + "=" + params[i];
      });
    }

    return new Promise<void>((resolve, reject) => {
      ApiService.get("admin/events", queryString)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_EVENTS, data["events"]);
          this.context.commit(Mutations.SET_EVENT_COUNT, data["total_result"]);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_EVENT](id) {
    ApiService.setHeader();

    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/events/${id}`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_EVENT, data["event"]);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.CREATE_EVENT](credentials) {
    ApiService.setHeader();

    const params = {
      event: {
        ...credentials,
      },
    };

    return new Promise<void>((resolve, reject) => {
      ApiService.post(`admin/events`, params)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_EVENT, data["event"]);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_EVENT](credentials) {
    ApiService.setHeader();

    const params = {
      event: {
        ...credentials.values,
      },
    };

    return new Promise<void>((resolve, reject) => {
      ApiService.put(`admin/events/${credentials.id}`, params)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_EVENT, data["event"]);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }
}
