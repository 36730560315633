import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";

export interface ProductBrand {
  id: number
  name: string
}

export interface ProductBrandInfo {
  product_brands: Array<ProductBrand>;
  product_brand: ProductBrand;
}

@Module
export default class ProductBrandModule
  extends VuexModule
  implements ProductBrandInfo
{
  product_brands = [] as Array<ProductBrand>;
  product_brand_count = 0;
  product_brand = {} as ProductBrand;

  get currentProductBrand(): ProductBrand {
    return this.product_brand;
  }

  get currentProductBrands(): Array<ProductBrand> {
    return this.product_brands;
  }

  get currentProductBrandCount() {
    return this.product_brand_count;
  }

  // get getErrors() {
  //   return this.errors;
  // }

  @Mutation
  [Mutations.SET_PRODUCT_BRAND](product_brand) {
    this.product_brand = product_brand
  }

  @Mutation
  [Mutations.SET_PRODUCT_BRANDS](product_brands) {
    this.product_brands = product_brands
  }

  @Mutation
  [Mutations.SET_PRODUCT_BRAND_COUNT](product_brand_count) {
    this.product_brand_count = product_brand_count
  }

  @Action
  [Actions.GET_PRODUCT_BRANDS](params) {
    ApiService.setHeader()

    let queryString = "?"

    if (params) {
      Object.keys(params).forEach((i) => {
        queryString += "&" + i + "=" + params[i];
      });
    }

    return new Promise<void>((resolve, reject) => {
      ApiService.get("admin/product_brands", queryString)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_PRODUCT_BRANDS,
            data["product_brands"]
          );
          this.context.commit(
            Mutations.SET_PRODUCT_BRAND_COUNT,
            data["total_result"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject();
        });
    });
  }

  @Action
  [Actions.GET_PRODUCT_BRAND](id) {
    ApiService.setHeader();

    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/product_brands/${id}`)
        .then(({ data }) => {
          console.log(data["product_brand"])
          this.context.commit(
            Mutations.SET_PRODUCT_BRAND,
            data["product_brand"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject();
        });
    });
  }

  @Action
  [Actions.CREATE_PRODUCT_BRAND](credentials) {
    ApiService.setHeader()

    const params = {
      product_brand: {
        ...credentials,
      },
    };

    return new Promise<void>((resolve, reject) => {
      ApiService.post(`admin/product_brands`, params)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_PRODUCT_BRAND,
            data["product_brand"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_PRODUCT_BRAND](credentials) {
    ApiService.setHeader()

    const params = {
      product_brand: {
        ...credentials.values,
      },
    };

    return new Promise<void>((resolve, reject) => {
      ApiService.put(`admin/product_brands/${credentials.id}`, params)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_PRODUCT_BRAND,
            data["product_brand"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_PRODUCT_BRAND](id) {
    ApiService.setHeader()

    return new Promise<void>((resolve, reject) => {
      ApiService.delete(`admin/product_brands/${id}`)
        .then(({ data }) => {
          resolve()
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject()
        })
    })
  }
}
