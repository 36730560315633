import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";

export interface ExportGroup {
  id: number
  name: string
}

export interface ExportGroupOption {
  id: string
  name: string
}

export interface ExportGroupInfo {
  export_groups: Array<ExportGroup>;
  export_group: ExportGroup;
}

@Module
export default class ExportGroupModule
  extends VuexModule
  implements ExportGroupInfo
{
  export_groups = [] as Array<ExportGroup>;
  export_group_count = 0;
  export_group = {} as ExportGroup;

  get currentExportGroup(): ExportGroup {
    return this.export_group;
  }

  get currentExportGroups(): Array<ExportGroup> {
    return this.export_groups;
  }

  get currentExportGroupCount() {
    return this.export_group_count;
  }

  // get getErrors() {
  //   return this.errors;
  // }

  @Mutation
  [Mutations.SET_EXPORT_GROUP](export_group) {
    this.export_group = export_group
  }

  @Mutation
  [Mutations.SET_EXPORT_GROUPS](export_groups) {
    this.export_groups = export_groups
  }

  @Mutation
  [Mutations.SET_EXPORT_GROUP_COUNT](export_group_count) {
    this.export_group_count = export_group_count
  }

  @Action
  [Actions.GET_EXPORT_GROUPS](params) {
    ApiService.setHeader()

    let queryString = "?"

    if (params) {
      Object.keys(params).forEach((i) => {
        queryString += "&" + i + "=" + params[i];
      });
    }

    return new Promise<void>((resolve, reject) => {
      ApiService.get("admin/export_groups", queryString)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_EXPORT_GROUPS,
            data["export_groups"]
          );
          this.context.commit(
            Mutations.SET_EXPORT_GROUP_COUNT,
            data["total_result"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject();
        });
    });
  }

  @Action
  [Actions.GET_EXPORT_GROUP](id) {
    ApiService.setHeader();

    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/export_groups/${id}`)
        .then(({ data }) => {
          console.log(data["export_group"])
          this.context.commit(
            Mutations.SET_EXPORT_GROUP,
            data["export_group"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject();
        });
    });
  }

  @Action
  [Actions.CREATE_EXPORT_GROUP](credentials) {
    ApiService.setHeader()

    const params = {
      export_group: {
        ...credentials,
      },
    };

    return new Promise<void>((resolve, reject) => {
      ApiService.post(`admin/export_groups`, params)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_EXPORT_GROUP,
            data["export_group"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_EXPORT_GROUP](credentials) {
    ApiService.setHeader()

    const params = {
      export_group: {
        ...credentials.values,
      },
    };

    return new Promise<void>((resolve, reject) => {
      ApiService.put(`admin/export_groups/${credentials.id}`, params)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_EXPORT_GROUP,
            data["export_group"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_EXPORT_GROUP](id) {
    ApiService.setHeader()

    return new Promise<void>((resolve, reject) => {
      ApiService.delete(`admin/export_groups/${id}`)
        .then(({ data }) => {
          resolve()
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject()
        })
    })
  }

  @Action
  [Actions.UPLOAD_EXCEL](params) {
    ApiService.setHeader()

    const formData = new FormData()
    const file = params['file']
    formData.append('file', file)

    return new Promise<void>((resolve, reject) => {
      ApiService.post_multipart(`admin/eppcms/${params.id}/upload_excel`, formData)
        .then(({ data }) => {
          // this.context.commit(
          //   Mutations.SET_PRODUCT,
          //   data["product"]
          // );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.DONE_EXPORT_GROUP](credentials) {
    ApiService.setHeader()

    return new Promise<void>((resolve, reject) => {
      ApiService.put(`admin/export_groups/${credentials.id}/done`, {})
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_EXPORT_GROUP,
            data["export_group"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject();
        });
    });
  }

  @Action
  [Actions.DRAFT_EXPORT_GROUP](credentials) {
    ApiService.setHeader()

    return new Promise<void>((resolve, reject) => {
      ApiService.put(`admin/export_groups/${credentials.id}/draft`, {})
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_EXPORT_GROUP,
            data["export_group"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject();
        });
    });
  }
}
