import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";

export interface Eppcm {
  id: number
  name: string
}

export interface EppcmOption {
  id: string
  name: string
}

export interface EppcmInfo {
  eppcms: Array<Eppcm>;
  eppcm: Eppcm;
}

@Module
export default class EppcmModule
  extends VuexModule
  implements EppcmInfo
{
  eppcms = [] as Array<Eppcm>;
  eppcm_count = 0;
  eppcm = {} as Eppcm;
  download_store_export_groups = {} as any

  get currentEppcm(): Eppcm {
    return this.eppcm;
  }

  get currentEppcms(): Array<Eppcm> {
    return this.eppcms;
  }

  get currentEppcmCount() {
    return this.eppcm_count;
  }

  get currentDownloadStoreExportGroups(){
    return this.download_store_export_groups
  }

  // get getErrors() {
  //   return this.errors;
  // }

  @Mutation
  [Mutations.SET_EPPCM](eppcm) {
    this.eppcm = eppcm
  }

  @Mutation
  [Mutations.SET_EPPCMS](eppcms) {
    this.eppcms = eppcms
  }

  @Mutation
  [Mutations.SET_EPPCM_COUNT](eppcm_count) {
    this.eppcm_count = eppcm_count
  }

  @Mutation
  [Mutations.SET_DOWNLOAD_STORE_EXPORT_GROUPS](download_store_export_groups) {
    this.download_store_export_groups = download_store_export_groups
  }

  @Action
  [Actions.GET_EPPCMS](params) {
    ApiService.setHeader()

    let queryString = "?"

    if (params) {
      Object.keys(params).forEach((i) => {
        queryString += "&" + i + "=" + params[i];
      });
    }

    return new Promise<void>((resolve, reject) => {
      ApiService.get("admin/eppcms", queryString)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_EPPCMS,
            data["eppcms"]
          );
          this.context.commit(
            Mutations.SET_EPPCM_COUNT,
            data["total_result"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject();
        });
    });
  }

  @Action
  [Actions.GET_EPPCM](id) {
    ApiService.setHeader();

    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/eppcms/${id}`)
        .then(({ data }) => {
          console.log(data["eppcm"])
          this.context.commit(
            Mutations.SET_EPPCM,
            data["eppcm"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject();
        });
    });
  }

  @Action
  [Actions.CREATE_EPPCM](credentials) {
    ApiService.setHeader()

    const params = {
      eppcm: {
        ...credentials,
      },
    };

    return new Promise<void>((resolve, reject) => {
      ApiService.post(`admin/eppcms`, params)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_EPPCM,
            data["eppcm"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_EPPCM](credentials) {
    ApiService.setHeader()

    const params = {
      eppcm: {
        ...credentials.values,
      },
    };

    return new Promise<void>((resolve, reject) => {
      ApiService.put(`admin/eppcms/${credentials.id}`, params)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_EPPCM,
            data["eppcm"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_EPPCM](id) {
    ApiService.setHeader()

    return new Promise<void>((resolve, reject) => {
      ApiService.delete(`admin/eppcms/${id}`)
        .then(({ data }) => {
          resolve()
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject()
        })
    })
  }

  @Action
  [Actions.DOWNLOAD_STORE_EXPORT_GROUPS](params) {
    ApiService.setHeader()

    // let queryString = "?"

    // if (params) {
    //   Object.keys(params).forEach((i) => {
    //     queryString += "&" + i + "=" + params[i]
    //   })
    // }

    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/eppcms/${params.id}/download_store_export_group`, '', {responseType: 'blob'})
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_DOWNLOAD_STORE_EXPORT_GROUPS,
            data
          )
          resolve()
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject()
        })
    })
  }

  @Action
  [Actions.CONFIRM_EPPCM](credentials) {
    ApiService.setHeader()

    return new Promise<void>((resolve, reject) => {
      ApiService.put(`admin/eppcms/${credentials.id}/confirm`, {})
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(
              Mutations.SET_EPPCM,
              data["eppcm"]
            );

            resolve();
          } else {
            this.context.commit(Mutations.SET_ERROR, data.error_msg)
            reject();
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject();
        });
    });
  }

  @Action
  [Actions.APPROVE_EPPCM](credentials) {
    ApiService.setHeader()

    return new Promise<void>((resolve, reject) => {
      ApiService.put(`admin/eppcms/${credentials.id}/approve`, {})
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_EPPCM,
            data["eppcm"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject();
        });
    });
  }

  @Action
  [Actions.REJECT_EPPCM](credentials) {
    ApiService.setHeader()

    return new Promise<void>((resolve, reject) => {
      ApiService.put(`admin/eppcms/${credentials.id}/reject`, {})
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_EPPCM,
            data["eppcm"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject();
        });
    });
  }
}
