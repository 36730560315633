import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";

export interface Banner {
  id: number
  image: string,
  image_url: string,
  link: string,
  sorting: number
}

export interface BannerInfo {
  banners: Array<Banner>
}

@Module
export default class BannerModule
  extends VuexModule
  implements BannerInfo
{
  banners = [] as Array<Banner>

  get currentBanners(): Array<Banner> {
    return this.banners;
  }

  // get getErrors() {
  //   return this.errors;
  // }

  @Mutation
  [Mutations.SET_BANNERS](banners) {
    this.banners = banners
  }

  @Action
  [Actions.GET_BANNERS]() {
    ApiService.setHeader()

    return new Promise<void>((resolve, reject) => {
      ApiService.get("admin/banners")
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_BANNERS,
            data["banners"]
          )
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject()
        })
    })
  }

  @Action
  [Actions.UPDATE_BANNERS](params) {
    ApiService.setHeader()

    console.log(params)

    return new Promise<void>((resolve, reject) => {
      ApiService.post(`admin/banners/update_all`, params)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_BANNERS,
            data["banners"]
          );
          resolve()
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject()
        })
    })
  }
}
