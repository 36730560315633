import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";

export interface Html {
  id: number
  name: string
}

export interface HtmlOption {
  id: string
  name: string
}

export interface HtmlInfo {
  htmls: Array<Html>;
  html: Html;
}

@Module
export default class HtmlModule
  extends VuexModule
  implements HtmlInfo
{
  htmls = [] as Array<Html>;
  html_count = 0;
  html = {} as Html;
  html_preview = '' as string;
  price_tiers = [] as Array<string>

  get currentHtml(): Html {
    return this.html;
  }

  get currentHtmlPreview(): string {
    return this.html_preview;
  }

  get currentPriceTiers(): Array<string> {
    return this.price_tiers;
  }

  get currentHtmls(): Array<Html> {
    return this.htmls;
  }

  get currentHtmlCount() {
    return this.html_count;
  }

  // get getErrors() {
  //   return this.errors;
  // }

  @Mutation
  [Mutations.SET_HTML](html) {
    this.html = html
  }

  @Mutation
  [Mutations.SET_HTML_PREVIEW](html_previe) {
    this.html_preview = html_previe
  }

  @Mutation
  [Mutations.SET_PRICE_TIERS](price_tiers) {
    this.price_tiers = price_tiers
  }

  @Mutation
  [Mutations.SET_HTMLS](htmls) {
    this.htmls = htmls
  }

  @Mutation
  [Mutations.SET_HTML_COUNT](html_count) {
    this.html_count = html_count
  }

  @Action
  [Actions.GET_HTMLS](params) {
    ApiService.setHeader()

    let queryString = "?"

    if (params) {
      Object.keys(params).forEach((i) => {
        queryString += "&" + i + "=" + params[i];
      });
    }

    return new Promise<void>((resolve, reject) => {
      ApiService.get("admin/htmls", queryString)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_HTMLS,
            data["htmls"]
          );
          this.context.commit(
            Mutations.SET_HTML_COUNT,
            data["total_result"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject();
        });
    });
  }

  @Action
  [Actions.GET_HTML](id) {
    ApiService.setHeader();

    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/htmls/${id}`)
        .then(({ data }) => {
          console.log(data["html"])
          this.context.commit(
            Mutations.SET_HTML,
            data["html"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject();
        });
    });
  }

  @Action
  [Actions.GET_HTML_PREVIEW](params) {
    ApiService.setHeader()

    let queryString = "?"

    if (params.params) {
      Object.keys(params.params).forEach((i) => {
        queryString += "&" + i + "=" + params.params[i];
      });
    }

    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/htmls/${params['id']}/get_html_preview`, queryString)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_HTML_PREVIEW,
            data["preview_html_url"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject();
        });
    });
  }

  @Action
  [Actions.GET_PRICE_TIERS](id) {
    ApiService.setHeader()

    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/htmls/${id}/index_price_tiers`)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_PRICE_TIERS,
            data["price_tiers"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject();
        });
    });
  }

  @Action
  [Actions.CREATE_HTML](credentials) {
    ApiService.setHeader()

    const params = {
      html: {
        ...credentials,
      },
    };

    return new Promise<void>((resolve, reject) => {
      ApiService.post(`admin/htmls`, params)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_HTML,
            data["html"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_HTML](credentials) {
    ApiService.setHeader()

    const params = {
      html: {
        ...credentials.values,
      },
    };

    return new Promise<void>((resolve, reject) => {
      ApiService.put(`admin/htmls/${credentials.id}`, params)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_HTML,
            data["html"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_HTML](id) {
    ApiService.setHeader()

    return new Promise<void>((resolve, reject) => {
      ApiService.delete(`admin/htmls/${id}`)
        .then(({ data }) => {
          resolve()
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject()
        })
    })
  }

  @Action
  [Actions.UPLOAD_HTML](params) {
    ApiService.setHeader()

    const formData = new FormData()
    const file = params['file']
    formData.append('file', file)
    formData.append('eppcm_id', params['eppcm_id'])

    return new Promise<void>((resolve, reject) => {
      ApiService.post_multipart(`admin/htmls/upload`, formData)
        .then(({ data }) => {
          // this.context.commit(
          //   Mutations.SET_PRODUCT,
          //   data["product"]
          // );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }
}
