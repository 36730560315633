import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";

export interface Level {
  id: number
  name: string,
  position: string,
  exp: string,
  level_up_description: number
}

export interface LevelInfo {
  levels: Array<Level>
}

@Module
export default class LevelModule
  extends VuexModule
  implements LevelInfo
{
  levels = [] as Array<Level>

  get currentLevels(): Array<Level> {
    return this.levels;
  }

  // get getErrors() {
  //   return this.errors;
  // }

  @Mutation
  [Mutations.SET_LEVELS](levels) {
    this.levels = levels
  }

  @Action
  [Actions.GET_LEVELS]() {
    ApiService.setHeader()

    return new Promise<void>((resolve, reject) => {
      ApiService.get("admin/levels")
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_LEVELS,
            data["levels"]
          )
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject()
        })
    })
  }
}
