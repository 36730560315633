import ApiService from "@/core/services/ApiService"
import JwtService from "@/core/services/JwtService"
import { Actions, Mutations } from "@/store/enums/StoreEnums"
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators"
import { AxiosRequestConfig } from "axios"

export interface RemoveBalanceRecord {
  id: number
  balance: number
}

export interface RemoveBalanceRecordInfo {
  remove_balance_records: Array<RemoveBalanceRecord>
  remove_balance_record: RemoveBalanceRecord
}

@Module
export default class RemoveBalanceRecordModule
  extends VuexModule
  implements RemoveBalanceRecordInfo
{
  remove_balance_records = [] as Array<RemoveBalanceRecord>
  remove_balance_record_count = 0
  remove_balance_record = {} as RemoveBalanceRecord

  get currentRemoveBalanceRecord(): RemoveBalanceRecord {
    return this.remove_balance_record
  }

  get currentRemoveBalanceRecords(): Array<RemoveBalanceRecord> {
    return this.remove_balance_records
  }

  get currentRemoveBalanceRecordCount() {
    return this.remove_balance_record_count
  }

  // get getErrors() {
  //   return this.errors;
  // }

  @Mutation
  [Mutations.SET_REMOVE_BALANCE_RECORDS](remove_balance_records) {
    this.remove_balance_records = remove_balance_records
  }

  @Mutation
  [Mutations.SET_REMOVE_BALANCE_RECORD](remove_balance_record) {
    this.remove_balance_record = remove_balance_record
  }

  @Mutation
  [Mutations.SET_REMOVE_BALANCE_RECORD_COUNT](remove_balance_record_count) {
    this.remove_balance_record_count = remove_balance_record_count
  }

  @Action
  [Actions.GET_REMOVE_BALANCE_RECORDS](params) {
    ApiService.setHeader()

    let queryString = "?"

    if (params) {
      Object.keys(params).forEach((i) => {
        queryString += "&" + i + "=" + params[i]
      })
    }

    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/remove_balance_records`, queryString)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_REMOVE_BALANCE_RECORDS,
            data["remove_balance_records"]
          );
          this.context.commit(
            Mutations.SET_REMOVE_BALANCE_RECORD_COUNT,
            data["total_result"]
          );
          resolve()
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject()
        })
    })
  }

  @Action
  [Actions.CREATE_REMOVE_BALANCE_RECORD](params) {
    ApiService.setHeader()

    return new Promise<void>((resolve, reject) => {
      ApiService.post(`admin/remove_balance_records`, params)
        .then(({ data }) => {
          if (!data.success) {
            this.context.commit(Mutations.SET_ERROR, data.error_msg)
            reject()
          }

          this.context.commit(
            Mutations.SET_REMOVE_BALANCE_RECORD,
            data["remove_balance_record"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject()
        })
    })
  }

  @Action
  [Actions.CONFIRM_REMOVE_BALANCE_RECORD](params) {
    ApiService.setHeader()

    return new Promise<void>((resolve, reject) => {
      ApiService.put(`admin/remove_balance_records/${params.id}/confirm`, params)
        .then(({ data }) => {
          if (!data.success) {
            this.context.commit(Mutations.SET_ERROR, data.error_msg)
            reject()
          }

          // this.context.commit(
          //   Mutations.SET_REMOVE_BALANCE_RECORD,
          //   data["remove_balance_record"]
          // );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject()
        })
    })
  }
  
  @Action
  [Actions.ABORT_REMOVE_BALANCE_RECORD](params) {
    ApiService.setHeader()

    return new Promise<void>((resolve, reject) => {
      ApiService.put(`admin/remove_balance_records/${params.id}/abort`, params)
        .then(({ data }) => {
          if (!data.success) {
            this.context.commit(Mutations.SET_ERROR, data.error_msg)
            reject()
          }

          // this.context.commit(
          //   Mutations.SET_REMOVE_BALANCE_RECORD,
          //   data["remove_balance_record"]
          // );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject()
        })
    })
  }
}
