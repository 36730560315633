import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";

export interface Tag {
  id: number
  name: string
}

export interface TagOption {
  id: string
  name: string
}

export interface TagInfo {
  tags: Array<Tag>;
  tag: Tag;
}

@Module
export default class TagModule
  extends VuexModule
  implements TagInfo
{
  tags = [] as Array<Tag>;
  tag_count = 0;
  tag = {} as Tag;

  get currentTag(): Tag {
    return this.tag;
  }

  get currentTags(): Array<Tag> {
    return this.tags;
  }

  get currentTagCount() {
    return this.tag_count;
  }

  // get getErrors() {
  //   return this.errors;
  // }

  @Mutation
  [Mutations.SET_TAG](tag) {
    this.tag = tag
  }

  @Mutation
  [Mutations.SET_TAGS](tags) {
    this.tags = tags
  }

  @Mutation
  [Mutations.SET_TAG_COUNT](tag_count) {
    this.tag_count = tag_count
  }

  @Action
  [Actions.GET_TAGS](params) {
    ApiService.setHeader()

    let queryString = "?"

    if (params) {
      Object.keys(params).forEach((i) => {
        queryString += "&" + i + "=" + params[i];
      });
    }

    return new Promise<void>((resolve, reject) => {
      ApiService.get("admin/tags", queryString)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_TAGS,
            data["tags"]
          );
          this.context.commit(
            Mutations.SET_TAG_COUNT,
            data["total_result"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject();
        });
    });
  }

  @Action
  [Actions.GET_TAG](id) {
    ApiService.setHeader();

    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/tags/${id}`)
        .then(({ data }) => {
          console.log(data["tag"])
          this.context.commit(
            Mutations.SET_TAG,
            data["tag"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject();
        });
    });
  }

  @Action
  [Actions.CREATE_TAG](credentials) {
    ApiService.setHeader()

    const params = {
      tag: {
        ...credentials,
      },
    };

    return new Promise<void>((resolve, reject) => {
      ApiService.post(`admin/tags`, params)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_TAG,
            data["tag"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject();
        });
    });
  }


  @Action
  [Actions.CREATE_TAG_ITEM](credentials) {
    ApiService.setHeader()

    const params = {
      tag_item: {
        ...credentials.values,
      },
    };

    return new Promise<void>((resolve, reject) => {
      ApiService.post(`admin/tags/${credentials.id}/create_tag_item`, params)
        .then(({ data }) => {
          // this.context.commit(
          //   Mutations.SET_TAG,
          //   data["tag"]
          // );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_TAG](credentials) {
    ApiService.setHeader()

    const params = {
      tag: {
        ...credentials.params,
      },
    };

    return new Promise<void>((resolve, reject) => {
      ApiService.put(`admin/tags/${credentials.id}`, params)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_TAG,
            data["tag"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_TAG](id) {
    ApiService.setHeader()

    return new Promise<void>((resolve, reject) => {
      ApiService.delete(`admin/tags/${id}`)
        .then(({ data }) => {
          resolve()
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject()
        })
    })
  }

  @Action
  [Actions.DELETE_TAG_ITEM](credentials) {
    ApiService.setHeader()

    return new Promise<void>((resolve, reject) => {
      ApiService.delete(`admin/tags/${credentials.id}/delete_tag_item?tag_item_id=${credentials.values['id']}`)
        .then(({ data }) => {
          resolve()
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject()
        })
    })
  } 
}
