import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";

export interface BoxCategory {
  id: number
  name: string
}

export interface BoxCategoryOption {
  id: string
  name: string
}

export interface BoxCategoryInfo {
  box_categories: Array<BoxCategory>;
  box_category: BoxCategory;
}

@Module
export default class BoxCategoryModule
  extends VuexModule
  implements BoxCategoryInfo
{
  box_categories = [] as Array<BoxCategory>;
  box_category_count = 0;
  box_category = {} as BoxCategory;

  get currentBoxCategory(): BoxCategory {
    return this.box_category;
  }

  get currentBoxCategories(): Array<BoxCategory> {
    return this.box_categories;
  }

  get currentBoxCategoryCount() {
    return this.box_category_count;
  }

  // get getErrors() {
  //   return this.errors;
  // }

  @Mutation
  [Mutations.SET_BOX_CATEGORY](box_category) {
    this.box_category = box_category
  }

  @Mutation
  [Mutations.SET_BOX_CATEGORIES](box_categories) {
    this.box_categories = box_categories
  }

  @Mutation
  [Mutations.SET_BOX_CATEGORY_COUNT](box_category_count) {
    this.box_category_count = box_category_count
  }

  @Action
  [Actions.GET_BOX_CATEGORIES](params) {
    ApiService.setHeader()

    let queryString = "?"

    if (params) {
      Object.keys(params).forEach((i) => {
        queryString += "&" + i + "=" + params[i];
      });
    }

    return new Promise<void>((resolve, reject) => {
      ApiService.get("admin/box_categories", queryString)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_BOX_CATEGORIES,
            data["box_categories"]
          );
          this.context.commit(
            Mutations.SET_BOX_CATEGORY_COUNT,
            data["total_result"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject();
        });
    });
  }

  @Action
  [Actions.GET_BOX_CATEGORY](id) {
    ApiService.setHeader();

    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/box_categories/${id}`)
        .then(({ data }) => {
          console.log(data["box_category"])
          this.context.commit(
            Mutations.SET_BOX_CATEGORY,
            data["box_category"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject();
        });
    });
  }

  @Action
  [Actions.CREATE_BOX_CATEGORY](credentials) {
    ApiService.setHeader()

    const params = {
      box_category: {
        ...credentials,
      },
    };

    return new Promise<void>((resolve, reject) => {
      ApiService.post(`admin/box_categories`, params)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_BOX_CATEGORY,
            data["box_category"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_BOX_CATEGORY](credentials) {
    ApiService.setHeader()

    const params = {
      box_category: {
        ...credentials.values,
      },
    };

    return new Promise<void>((resolve, reject) => {
      ApiService.put(`admin/box_categories/${credentials.id}`, params)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_BOX_CATEGORY,
            data["box_category"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_BOX_CATEGORY](id) {
    ApiService.setHeader()

    return new Promise<void>((resolve, reject) => {
      ApiService.delete(`admin/box_categories/${id}`)
        .then(({ data }) => {
          resolve()
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject()
        })
    })
  }
}
