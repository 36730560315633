import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";

export interface CouponItem {
  id: number,
  no: string,
  name: string,
}
export interface CouponDeliverEvent {
  name: string,
  // surname: string;
  // email: string;
  // password: string;
  // api_token: string;
}
export interface CouponItemInfo {
  coupon_items: Array<CouponItem>,
  coupon_item: CouponItem,
  gift_coupons: Array<GiftCoupon>,
  coupon_deliver_events: Array<CouponDeliverEvent>,
  coupon_deliver_event: CouponDeliverEvent,
}

export interface CouponSettings {
  coupon_usage_amount: string,
  coupon_usage_cycle: string,
  coupon_taken_limit: string,
  coupon_taken_cycle: string,
  coupon_available_days: string,
  coupon_available_rule: string,
  coupon_single_day_limit: string,
}

export interface CouponDeliverEventItem {
  id: number,
  no: string,
  name: string,
  amount: number,
  deliver_type: string,
  coupon_type: string,
  single_quantity: number,
  total_quantity: number,
  deliver_count: number,
  left_count: number,
  total_count: number,
  total_amount: number,
  taked_count: number,
  coupon_item_id: number,
}

export interface GiftCoupon {
  id: number,
  no: string,
  name: string,
  selected: boolean,
}

@Module
export default class CouponItemModule
  extends VuexModule
  implements CouponItemInfo
{
  coupon_items = [] as Array<CouponItem>
  coupon_item_count = 0
  coupon_item = {} as CouponItem
  coupon_deliver_events = [] as Array<CouponDeliverEvent>
  coupon_deliver_event = {} as CouponDeliverEvent
  coupon_settings = {} as CouponSettings
  coupon_deliver_event_items = [] as Array<CouponDeliverEventItem>
  gift_coupons = [] as Array<GiftCoupon>

  get currentCouponItem(): CouponItem {
    return this.coupon_item
  }

  get currentCouponItems(): Array<CouponItem> {
    return this.coupon_items
  }

  get currentCouponItemCount() {
    return this.coupon_item_count
  }

  get currentCouponDeliverEvents(): Array<CouponDeliverEvent> {
    return this.coupon_deliver_events
  }

  get currentCouponDeliverEvent(): CouponDeliverEvent {
    return this.coupon_deliver_event
  }

  get currentCouponSettings(): CouponSettings {
    return this.coupon_settings
  }

  get currentCouponDeliverEventItems(): Array<CouponDeliverEventItem> {
    return this.coupon_deliver_event_items
  }

  get currentGiftCoupons(): Array<GiftCoupon> {
    return this.gift_coupons
  }

  // get getErrors() {
  //   return this.errors;
  // }

  @Mutation
  [Mutations.SET_GIFT_COUPONS](gift_coupons) {
    this.gift_coupons = gift_coupons
  }

  @Mutation
  [Mutations.SET_COUPON_DELIVER_EVENT_ITEMS](coupon_deliver_event_items) {
    this.coupon_deliver_event_items = coupon_deliver_event_items
  }

  @Mutation
  [Mutations.SET_COUPON_SETTINGS](coupon_settings) {
    this.coupon_settings = coupon_settings
  }

  @Mutation
  [Mutations.SET_COUPON_ITEM](coupon_item) {
    this.coupon_item = coupon_item
  }

  @Mutation
  [Mutations.SET_COUPON_ITEMS](coupon_items) {
    this.coupon_items = coupon_items
  }

  @Mutation
  [Mutations.SET_COUPON_ITEM_COUNT](coupon_item_count) {
    this.coupon_item_count = coupon_item_count
  }

  @Mutation
  [Mutations.SET_COUPON_DELIVER_EVENT](coupon_deliver_event) {
    this.coupon_deliver_event = coupon_deliver_event
  }

  @Mutation
  [Mutations.SET_COUPON_DELIVER_EVENTS](coupon_deliver_events) {
    this.coupon_deliver_events = coupon_deliver_events
  }


  @Action
  [Actions.UPDATE_COUPON_ITEM_SHOPS](params) {
    ApiService.setHeader()

    return new Promise<void>((resolve, reject) => {
      ApiService.put(`admin/coupon_items/${params.coupon_item.id}/update_shops`, params)
        .then(({ data }) => {
          resolve()
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject()
        });
    })
  }

  @Action
  [Actions.DELETE_COUPON_ITEM](id) {
    ApiService.setHeader()

    return new Promise<void>((resolve, reject) => {
      ApiService.delete(`admin/coupon_items/${id}`)
        .then(({ data }) => {
          resolve()
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject()
        })
    })
  }

  @Action
  [Actions.GET_COUPON_ITEMS](params) {
    ApiService.setHeader()

    let queryString = "?"

    if (params) {
      Object.keys(params).forEach((i) => {
        queryString += "&" + i + "=" + params[i]
      })
    }

    return new Promise<void>((resolve, reject) => {
      ApiService.get("admin/coupon_items", queryString)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_COUPON_ITEMS,
            data["coupon_items"]
          );
          this.context.commit(
            Mutations.SET_COUPON_ITEM_COUNT,
            data["total_result"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_COUPON_ITEM](id) {
    ApiService.setHeader();

    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/coupon_items/${id}`)
        .then(({ data }) => {
          console.log(data["coupon_item"]);
          this.context.commit(
            Mutations.SET_COUPON_ITEM,
            data["coupon_item"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.CREATE_COUPON_ITEM](credentials) {
    ApiService.setHeader();

    const params = {
      coupon_item: {
        ...credentials,
      },
    };

    return new Promise<void>((resolve, reject) => {
      ApiService.post(`admin/coupon_items`, params)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_COUPON_ITEM,
            data["coupon_item"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_COUPON_ITEM](credentials) {
    ApiService.setHeader();

    const params = {
      coupon_item: {
        ...credentials.values,
      },
    };

    return new Promise<void>((resolve, reject) => {
      ApiService.put(`admin/coupon_items/${credentials.id}`, params)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_COUPON_ITEM,
            data["coupon_item"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_COUPON_DELIVER_EVENTS](id) {
    ApiService.setHeader()

    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/budget_quarters/${id}/coupon_deliver_events`)
        .then(({ data }) => {
          console.log(data["coupon_deliver_events"])
          this.context.commit(
            Mutations.SET_COUPON_DELIVER_EVENTS,
            data["coupon_deliver_events"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_COUPON_DELIVER_EVENT](params) {
    ApiService.setHeader();

    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/budget_quarters/${params.coupon_deliver_event.budget_quarter_id}/coupon_deliver_events/${params.coupon_deliver_event.id}`)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_COUPON_DELIVER_EVENT,
            data["coupon_deliver_event"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.CREATE_COUPON_DELIVER_EVENT](params) {
    ApiService.setHeader();

    return new Promise<void>((resolve, reject) => {
      ApiService.post(`admin/budget_quarters/${params.coupon_deliver_event.budget_quarter_id}/coupon_deliver_events`, params)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_COUPON_DELIVER_EVENT,
            data["coupon_deliver_event"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_COUPON_DELIVER_EVENT](params) {
    ApiService.setHeader();

    return new Promise<void>((resolve, reject) => {
      ApiService.put(`admin/budget_quarters/${params.coupon_deliver_event.budget_quarter_id}/coupon_deliver_events/${params.coupon_deliver_event.id}`, params)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_COUPON_DELIVER_EVENT,
            data["coupon_deliver_event"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_COUPON_SETTINGS]() {
    ApiService.setHeader();

    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/system_settings/show_coupon_settings`)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_COUPON_SETTINGS,
            data["coupon_settings"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_COUPON_SETTINGS](params) {
    ApiService.setHeader()

    return new Promise<void>((resolve, reject) => {
      ApiService.put(`admin/system_settings/update_coupon_settings`, params)
        .then(({ data }) => {
          resolve()
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject()
        });
    })
  }


  @Action
  [Actions.GET_COUPON_DELIVER_EVENT_ITEMS](params) {
    ApiService.setHeader()

    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/budget_quarters/${params.coupon_deliver_event.budget_quarter_id}/coupon_deliver_events/${params.coupon_deliver_event.id}/coupon_deliver_event_items`)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_COUPON_DELIVER_EVENT_ITEMS,
            data["coupon_deliver_event_items"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_COUPON_DELIVER_EVENT_ITEMS](params) {
    ApiService.setHeader() 

    return new Promise<void>((resolve, reject) => {
      ApiService.put(`admin/budget_quarters/${params.coupon_deliver_event.budget_quarter_id}/coupon_deliver_events/${params.coupon_deliver_event.id}`, params)
        .then(({ data }) => {
          console.log('--- SET_COUPON_DELIVER_EVENT_ITEMS')
          console.log(data)
          console.log(data['coupon_deliver_event']['coupon_deliver_event_items'])
          this.context.commit(
            Mutations.SET_COUPON_DELIVER_EVENT_ITEMS,
            data['coupon_deliver_event']['coupon_deliver_event_items']
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_GIFT_COUPONS]() {
    ApiService.setHeader()

    return new Promise<void>((resolve, reject) => {
      ApiService.get("admin/coupon_items/gift_coupons_index")
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_GIFT_COUPONS,
            data["coupon_items"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }
}
