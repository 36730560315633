import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";

export interface User {
  name: string;
  surname: string;
  email: string;
  password: string;
  api_token: string;
}

export interface UserAuthInfo {
  errors: unknown;
  user: User;
  isAuthenticated: boolean;
}

const USER_KEY = "user" as string;
@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {

  errors = {}
  user = {} as User
  isAuthenticated = !!JwtService.getToken()

  /**
   * Get current user object
   * @returns User
   */
  // get currentUser(): User {
  //   return this.user
  // }
  get currentUser(): User | null {
    const user_str = window.localStorage.getItem(USER_KEY)
    
    if (!user_str) {
      return null
    }

    return JSON.parse(user_str) as User
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors() {
    return this.errors
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error
  }

  @Mutation
  [Mutations.SET_AUTH](user) {
    this.isAuthenticated = true
    this.user = user
    window.localStorage.setItem(USER_KEY, JSON.stringify(user))
    // this.context.commit(Mutations.SET_USER, user);
    this.errors = []
    JwtService.saveToken(this.user["api_token"])
  }

  @Mutation
  [Mutations.SET_USER](user) {
    // this.user = user
    // console.log(user)
    // window.localStorage.setItem(USER_KEY, user)
  }

  @Mutation
  [Mutations.SET_PASSWORD](password) {
    this.user.password = password;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    window.localStorage.removeItem(USER_KEY)
    JwtService.destroyToken();
    ApiService.removeHeader()
  }

  @Action
  [Actions.LOGIN](credentials) {
    const params = {
      admin: {
        ...credentials,
      },
    };
    return new Promise<void>((resolve, reject) => {
      ApiService.post("admin/login", params)
        .then(({ data }) => {
          if (data["admin"]) {
            this.context.commit(Mutations.SET_AUTH, data["admin"]);
          }

          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.LOGIN_VIA_SSO](token) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`/admin/sso_login?ssoToken=${token}`)
        .then(({ data }) => {
          console.log(data)
          console.log(data["admin"])
          if (data["admin"]) {
            this.context.commit(Mutations.SET_AUTH, data["admin"]);
          }

          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
  }

  // @Action
  // [Actions.REGISTER](credentials) {
  //   return new Promise<void>((resolve, reject) => {
  //     ApiService.post("register", credentials)
  //       .then(({ data }) => {
  //         this.context.commit(Mutations.SET_AUTH, data);
  //         resolve();
  //       })
  //       .catch(({ response }) => {
  //         this.context.commit(Mutations.SET_ERROR, response.data.errors);
  //         reject();
  //       });
  //   });
  // }

  // @Action
  // [Actions.FORGOT_PASSWORD](payload) {
  //   const params = {
  //     params: {
  //       ...payload,
  //     },
  //   };
  //   return new Promise<void>((resolve, reject) => {
  //     ApiService.query("forgot_password", params)
  //       .then(({ data }) => {
  //         this.context.commit(Mutations.SET_AUTH, data);
  //         resolve();
  //       })
  //       .catch(({ response }) => {
  //         this.context.commit(Mutations.SET_ERROR, response.data.errors);
  //         reject();
  //       });
  //   });
  // }

  @Action
  [Actions.VERIFY_AUTH]() {
    if (JwtService.getToken()) {
      ApiService.setHeader();

      const params = {
        params: {
          token: JwtService.getToken(),
        },
      };
      ApiService.query("admin/verify_token", params as AxiosRequestConfig)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data["admin"]);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          this.context.commit(Mutations.PURGE_AUTH);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  // @Action
  // [Actions.UPDATE_USER](payload) {
  //   ApiService.setHeader();
  //   return new Promise<void>((resolve, reject) => {
  //     ApiService.post("update_user", payload)
  //       .then(({ data }) => {
  //         this.context.commit(Mutations.SET_USER, data);
  //         resolve();
  //       })
  //       .catch(({ response }) => {
  //         this.context.commit(Mutations.SET_ERROR, response.data.errors);
  //         reject();
  //       });
  //   });
  // }
}
