import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";

export interface PriceCheckRecord {
  id: number
  name: string
}

export interface PriceCheckRecordOption {
  id: string
  name: string
}

export interface PriceCheckRecordInfo {
  price_check_records: Array<PriceCheckRecord>;
  price_check_record: PriceCheckRecord;
}

@Module
export default class PriceCheckRecordModule
  extends VuexModule
  implements PriceCheckRecordInfo
{
  price_check_records = [] as Array<PriceCheckRecord>;
  price_check_record_count = 0;
  price_check_record = {} as PriceCheckRecord;

  get currentPriceCheckRecord(): PriceCheckRecord {
    return this.price_check_record;
  }

  get currentPriceCheckRecords(): Array<PriceCheckRecord> {
    return this.price_check_records;
  }

  get currentPriceCheckRecordCount() {
    return this.price_check_record_count;
  }

  // get getErrors() {
  //   return this.errors;
  // }

  @Mutation
  [Mutations.SET_PRICE_CHECK_RECORD](price_check_record) {
    this.price_check_record = price_check_record
  }

  @Mutation
  [Mutations.SET_PRICE_CHECK_RECORDS](price_check_records) {
    this.price_check_records = price_check_records
  }

  @Mutation
  [Mutations.SET_PRICE_CHECK_RECORD_COUNT](price_check_record_count) {
    this.price_check_record_count = price_check_record_count
  }

  @Action
  [Actions.GET_PRICE_CHECK_RECORDS](params) {
    ApiService.setHeader()

    let queryString = "?"

    if (params) {
      Object.keys(params).forEach((i) => {
        queryString += "&" + i + "=" + params[i];
      });
    }

    return new Promise<void>((resolve, reject) => {
      ApiService.get("admin/price_check_records", queryString)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_PRICE_CHECK_RECORDS,
            data["price_check_records"]
          );
          this.context.commit(
            Mutations.SET_PRICE_CHECK_RECORD_COUNT,
            data["total_result"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject();
        });
    });
  }

  @Action
  [Actions.GET_PRICE_CHECK_RECORD](id) {
    ApiService.setHeader();

    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/price_check_records/${id}`)
        .then(({ data }) => {
          console.log(data["price_check_record"])
          this.context.commit(
            Mutations.SET_PRICE_CHECK_RECORD,
            data["price_check_record"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject();
        });
    });
  }

  @Action
  [Actions.UPLOAD_PRICE_EXCEL](params) {
    ApiService.setHeader()

    const formData = new FormData()
    const file = params['file']
    formData.append('file', file)

    return new Promise<void>((resolve, reject) => {
      ApiService.post_multipart(`admin/price_check_records/upload_excel`, formData)
        .then(({ data }) => {
          // this.context.commit(
          //   Mutations.SET_PRODUCT,
          //   data["product"]
          // );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }
}
