import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";

export interface Member {
  id: number
  nickname: string
  mobile: string
  balance: number
  membership_point: number
  point: number
  level: string
  exp: number
  next_level_exp: number
  is_black: boolean
  created_at: string
  updated_at: string
}

@Module
export default class MemberModule extends VuexModule {
  members = [] as Array<Member>
  member_count = 0
  member = {} as Member

  get currentMembers(): Array<Member> {
    return this.members
  }

  get currentMemberCount() {
    return this.member_count
  }

  get currentMember(): Member {
    return this.member
  }

  @Mutation
  [Mutations.SET_MEMBERS](members) {
    this.members = members;
  }

  @Mutation
  [Mutations.SET_MEMBER_COUNT](member_count) {
    this.member_count = member_count;
  }

  @Mutation
  [Mutations.SET_MEMBER](member) {
    this.member = member;
  }

  @Action
  [Actions.GET_MEMBERS](params) {
    ApiService.setHeader();

    let queryString = "?";

    if (params) {
      Object.keys(params).forEach((i) => {
        queryString += "&" + i + "=" + params[i];
      });
    }

    return new Promise<void>((resolve, reject) => {
      ApiService.get("admin/users", queryString)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_MEMBERS, data["users"]);
          this.context.commit(Mutations.SET_MEMBER_COUNT, data["total_result"]);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_MEMBER](id) {
    ApiService.setHeader()

    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/users/${id}`)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_MEMBER,
            data["user"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject()
        })
    })
  }

  @Action
  [Actions.UPDATE_MEMBER](credentials) {
    ApiService.setHeader()

    const params = {
      user: {
        ...credentials.values,
      },
    };

    return new Promise<void>((resolve, reject) => {
      ApiService.put(`admin/users/${credentials.id}`, params)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_USER,
            data["user"]
          );
          resolve()
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject()
        })
    })
  }

  @Action
  [Actions.ADD_MEMBER_BALANCE](credentials) {
    ApiService.setHeader()

    const params = {
      user: {
        ...credentials.value,
      },
    };

    return new Promise<void>((resolve, reject) => {
      ApiService.post(`admin/users/${credentials.id}/add_balance`, params)
        .then(({ data }) => {
          // this.context.commit(
          //   Mutations.SET_PRODUCT,
          //   data["product"]
          // );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject();
        });
    });
  }

  @Action
  [Actions.ADD_MEMBER_POINT](credentials) {
    ApiService.setHeader()

    const params = {
      user: {
        ...credentials.value,
      },
    };

    return new Promise<void>((resolve, reject) => {
      ApiService.post(`admin/users/${credentials.id}/add_point`, params)
        .then(({ data }) => {
          // this.context.commit(
          //   Mutations.SET_PRODUCT,
          //   data["product"]
          // );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject();
        });
    });
  }
}
