import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import BusinessDistrictModule from "@/store/modules/BusinessDistrictModule";
import ShopModule from "@/store/modules/ShopModule";
import BudgetModule from "@/store/modules/BudgetModule";
import AdminModule from "@/store/modules/AdminModule";
import UserModule from "@/store/modules/UserModule";
import OrderModule from "@/store/modules/OrderModule";
import RoleModule from "@/store/modules/RoleModule";
import EventModule from "@/store/modules/EventModule";
import CouponModule from "@/store/modules/CouponModule";
import SystemSettingModule from "@/store/modules/SystemSettingModule";
import NewsItemModule from "@/store/modules/NewsItemModule";

import ProductBrandModule from "@/store/modules/ProductBrandModule"
import ProductModule from "@/store/modules/ProductModule"
import BoxCategoryModule from "@/store/modules/BoxCategoryModule"
import BoxInfoModule from "@/store/modules/BoxInfoModule"
import MemberModule from "@/store/modules/MemberModule"
import IpModule from "@/store/modules/IpModule"
import BannerModule from "@/store/modules/BannerModule"
import RemoveBalanceRecordModule from "@/store/modules/RemoveBalanceRecordModule"
import AddBalanceRecordModule from "@/store/modules/AddBalanceRecordModule"
import AddPointRecordModule from "@/store/modules/AddPointRecordModule"
import ShippingOrderModule from "@/store/modules/ShippingOrderModule"
import LevelModule from "@/store/modules/LevelModule"
import EppcmModule from "@/store/modules/EppcmModule"
import HtmlModule from "@/store/modules/HtmlModule"
import TagModule from "@/store/modules/TagModule"
import ExportGroupModule from "@/store/modules/ExportGroupModule"
import PriceCheckRecordModule from "@/store/modules/PriceCheckRecordModule"
import PcmVersionModule from "@/store/modules/PcmVersionModule"

config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    BusinessDistrictModule,
    ShopModule,
    BudgetModule,
    AdminModule,
    UserModule,
    RoleModule,
    CouponModule,
    OrderModule,
    EventModule,
    SystemSettingModule,
    NewsItemModule,

    ProductBrandModule,
    ProductModule,
    BoxInfoModule,
    BoxCategoryModule,
    MemberModule,
    IpModule,
    BannerModule,
    RemoveBalanceRecordModule,
    AddBalanceRecordModule,
    AddPointRecordModule,
    ShippingOrderModule,
    LevelModule,
    EppcmModule,
    HtmlModule,
    TagModule,
    ExportGroupModule,
    PriceCheckRecordModule,
    PcmVersionModule
  }
})

export default store
