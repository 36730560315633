import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";

export interface BoxRecord {
  id: number,
  user: string,
  product: string,
  item_type: number,
  price: string,
  updated_at: string,
  created_at: string
}

export interface BoxItemInfo {
  product_id: number,
  product: string,
  brand: string,
  price: number,
  recycle_price: number,
  item_type: string,
  quantity: number,
  range_from: number,
  range_to: number,
  level: string,
  sorting: number
}

export interface BoxType {
  key: string,
  value: string
}

export interface BoxInfo {
  id: number,
  name: string,
  image: string,
  image_url: string,
  price: number,
  get_limit: number,
  is_crash: boolean,
  is_hide: boolean,
  is_infinity_crash: boolean,
  is_increase_membership_point: boolean,
  is_auto_open: boolean,
  auto_open_count: number,
  box_item_infos_attributes: Array<BoxItemInfo>,
  hidden_gifts_attributes: Array<any>,
  game_type: string
}

export interface Box {
  id: number,
  name: string
}

export interface BoxInfoInfo {
  box_types: Array<BoxType>
  game_types: Array<BoxType>
  item_levels: Array<BoxType>
  box_item_types: Array<BoxType>
  box_infos: Array<BoxInfo>
  box_info: BoxInfo,
  boxes: Array<Box>
  box_records: Array<BoxRecord>
}

@Module
export default class BoxInfoModule
  extends VuexModule
  implements BoxInfoInfo
{
  box_types = [] as Array<BoxType>
  game_types = [] as Array<BoxType>
  item_levels = [] as Array<BoxType>
  box_item_types = [] as Array<BoxType>
  box_infos = [] as Array<BoxInfo>
  box_info_count = 0
  box_info = {} as BoxInfo
  boxes = [] as Array<Box>
  box_count = 0
  box_records = [] as Array<BoxRecord>
  box_record_count = 0

  get currentBoxInfo(): BoxInfo {
    return this.box_info
  }

  get currentBoxTypes(): Array<BoxType> {
    return this.box_types
  }

  get currentGameTypes(): Array<BoxType> {
    return this.game_types
  }

  get currentBoxItemLevels(): Array<BoxType> {
    return this.item_levels
  }

  get currentBoxItemTypes(): Array<BoxType> {
    return this.box_item_types
  }

  get currentBoxInfos(): Array<BoxInfo> {
    return this.box_infos
  }

  get currentBoxTypeOptions(): Array<BoxInfo> {
    return this.box_infos
  }

  get currentBoxes(): Array<Box> {
    return this.boxes
  }

  get currentBoxRecords(): Array<BoxRecord> {
    return this.box_records
  }

  get currentBoxInfoCount() {
    return this.box_info_count
  }

  get currentBoxCount() {
    return this.box_count
  }

  get currentBoxRecordCount() {
    return this.box_record_count
  }

  // get getErrors() {
  //   return this.errors;
  // }

  @Mutation
  [Mutations.SET_BOX_INFO](box_info) {
    this.box_info = box_info
  }

  @Mutation
  [Mutations.SET_BOX_TYPES](box_types) {
    this.box_types = box_types
  }

  @Mutation
  [Mutations.SET_GAME_TYPES](game_types) {
    this.game_types = game_types
  }

  @Mutation
  [Mutations.SET_BOX_ITEM_LEVELS](item_levels) {
    this.item_levels = item_levels
  }

  @Mutation
  [Mutations.SET_BOX_ITEM_TYPES](box_item_types) {
    this.box_item_types = box_item_types
  }

  @Mutation
  [Mutations.SET_BOX_INFOS](box_infos) {
    this.box_infos = box_infos
  }

  @Mutation
  [Mutations.SET_BOXES](boxes) {
    this.boxes = boxes
  }

  @Mutation
  [Mutations.SET_BOX_RECORDS](box_records) {
    this.box_records = box_records
  }

  @Mutation
  [Mutations.SET_BOX_INFO_COUNT](box_info_count) {
    this.box_info_count = box_info_count
  }

  @Mutation
  [Mutations.SET_BOX_COUNT](box_count) {
    this.box_count = box_count
  }

  @Mutation
  [Mutations.SET_BOX_RECORD_COUNT](box_record_count) {
    this.box_record_count = box_record_count
  }

  @Action
  [Actions.GET_BOX_TYPES](params) {
    ApiService.setHeader()

    return new Promise<void>((resolve, reject) => {
      ApiService.get("admin/box_infos/index_box_type")
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_BOX_TYPES,
            data["box_types"]
          )

          resolve()
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject()
        })
    })
  }

  @Action
  [Actions.GET_GAME_TYPES](params) {
    ApiService.setHeader()

    return new Promise<void>((resolve, reject) => {
      ApiService.get("admin/box_infos/index_game_type")
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_GAME_TYPES,
            data["game_types"]
          )

          resolve()
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject()
        })
    })
  }

  @Action
  [Actions.GET_BOX_ITEM_LEVELS](params) {
    ApiService.setHeader()

    return new Promise<void>((resolve, reject) => {
      ApiService.get("admin/box_infos/index_item_level")
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_BOX_ITEM_LEVELS,
            data["levels"]
          )

          resolve()
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject()
        })
    })
  }

  @Action
  [Actions.GET_BOX_ITEM_TYPES](params) {
    ApiService.setHeader()

    return new Promise<void>((resolve, reject) => {
      ApiService.get("admin/box_infos/index_box_item_type")
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_BOX_ITEM_TYPES,
            data["box_item_types"]
          )

          resolve()
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject()
        })
    })
  }

  @Action
  [Actions.GET_BOXES](params) {
    ApiService.setHeader()

    let queryString = "?"

    if (params) {
      Object.keys(params).forEach((i) => {
        queryString += "&" + i + "=" + params[i];
      });
    }

    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/box_infos/${params.box_info_id}/boxes`, queryString)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_BOXES,
            data["boxes"]
          );
          this.context.commit(
            Mutations.SET_BOX_COUNT,
            data["total_result"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject();
        });
    });
  }

  @Action
  [Actions.GET_BOX_RECORDS](params) {
    ApiService.setHeader()

    let queryString = "?"

    if (params) {
      Object.keys(params).forEach((i) => {
        queryString += "&" + i + "=" + params[i];
      });
    }

    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/box_infos/${params.box_info_id}/boxes/${params.box_id}/box_records`, queryString)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_BOX_RECORDS,
            data["box_records"]
          );
          this.context.commit(
            Mutations.SET_BOX_RECORD_COUNT,
            data["total_result"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject();
        });
    });
  }

  @Action
  [Actions.GET_BOX_INFOS](params) {
    ApiService.setHeader()

    let queryString = "?"

    if (params) {
      Object.keys(params).forEach((i) => {
        queryString += "&" + i + "=" + params[i];
      });
    }

    return new Promise<void>((resolve, reject) => {
      ApiService.get("admin/box_infos", queryString)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_BOX_INFOS,
            data["box_infos"]
          );
          this.context.commit(
            Mutations.SET_BOX_INFO_COUNT,
            data["total_result"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject();
        });
    });
  }

  @Action
  [Actions.GET_BOX_INFO](id) {
    ApiService.setHeader();

    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/box_infos/${id}`)
        .then(({ data }) => {
          console.log(data["box_info"])
          this.context.commit(
            Mutations.SET_BOX_INFO,
            data["box_info"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject();
        });
    });
  }

  @Action
  [Actions.CREATE_BOXES](credentials) {
    ApiService.setHeader()

    const params = {
      box: {
        times: credentials.times
      }
    }

    return new Promise<void>((resolve, reject) => {
      ApiService.post(`admin/box_infos/${credentials.box_info_id}/boxes`, params)
        .then(({ data }) => {
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject();
        });
    });
  }

  @Action
  [Actions.CREATE_BOX_INFO](credentials) {
    ApiService.setHeader()

    const params = {
      box_info: {
        ...credentials,
      },
    };

    return new Promise<void>((resolve, reject) => {
      ApiService.post(`admin/box_infos`, params)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_BOX_INFO,
            data["box_info"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_BOX_INFO](credentials) {
    ApiService.setHeader()

    const params = {
      box_info: {
        ...credentials.values,
      },
    };

    return new Promise<void>((resolve, reject) => {
      ApiService.put(`admin/box_infos/${credentials.id}`, params)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_BOX_INFO,
            data["box_info"]
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject();
        });
    });
  }
}
